<template>
  <onboarding-scaffold>
    <template v-slot:content>
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <div class="w-full flex items-center justify-center">
        <div
          class="
            xl:w-1/2
            lg:w-full
            md:w-full
            w-full
            min-h-screen
            py-20
            xl:px-28
            lg:px-0
            md:px-0
            px-0
          "
        >
          <form-scaffold button-text="Verify account" @sendAction="send">
            <span slot="heading-text">Verify your Account</span>
            <div slot="field-instructions">
              Enter the five(5) digit verification code sent to your
              {{
                $route.query.verification_mode === 'email'
                  ? 'Email Address'
                  : 'Phone'
              }}
              to verify your account.
            </div>
            <div slot="input-fields">
              <div class="mt-8">
                <div
                  class="
                    flex
                    items-center
                    justify-between
                    text-legend-gray
                    dark:text-border-color
                    my-3
                  "
                >
                  <span class="font-semibold">Enter Verification code</span>
                </div>
                <CodeInput
                  :loading="false"
                  :autoFocus="true"
                  class="input"
                  :fields="5"
                  @complete="onComplete"
                />
              </div>
              <span
                class="
                  inline-block
                  text-primary-color
                  font-semibold
                  pt-7
                  pb-6
                  cursor-pointer
                "
                @click="chooseVerifyMode = true"
              >
                Resend Verification code?
              </span>
            </div>
          </form-scaffold>
        </div>
      </div>
      <section
        v-if="chooseVerifyMode"
        class="
          w-screen
          h-screen
          bg-legend-black bg-opacity-30
          flex
          items-center
          justify-center
          fixed
          top-0
          left-0
        "
      >
        <div class="bg-white w-1/3 py-10 px-12 rounded-lg text-legend-gray">
          <h1 class="text-2xl font-bold text-legend-black">
            Verification mode
          </h1>
          <div class="mt-8 mb-5 font-semibold">
            How do you want to receive your verification code?
          </div>
          <div class="flex justify-between text-legend-gray uppercase mb-16">
            <div
              class="
                w-1/2
                p-5
                mr-5
                rounded-lg
                shadow-lg
                bg-white
                flex
                items-center
                border
                cursor-pointer
              "
              :class="{ 'border-primary-color': isSMS }"
              @click="chooseSMS"
            >
              <img
                v-if="!isSMS"
                src="@/assets/images/checkbox-unchecked.svg"
                alt="via Phone number"
              />
              <div
                v-else
                style="width: 18px; height: 18px"
                class="
                  bg-primary-color
                  rounded
                  flex
                  items-center
                  justify-center
                "
              >
                <img
                  src="@/assets/images/check-mark.svg"
                  alt="via email address"
                />
              </div>
              <span class="font-bold text-xs ml-3.5">Phone number</span>
            </div>
            <div
              class="
                w-1/2
                p-5
                rounded-lg
                shadow-lg
                bg-white
                flex
                items-center
                border
                cursor-pointer
              "
              :class="{ 'border-primary-color': isEmail }"
              @click="chooseEmail"
            >
              <img
                v-if="!isEmail"
                src="@/assets/images/checkbox-unchecked.svg"
                alt="via Phone number"
              />
              <div
                v-else
                style="width: 18px; height: 18px"
                class="
                  bg-primary-color
                  rounded
                  flex
                  items-center
                  justify-center
                "
              >
                <img
                  src="@/assets/images/check-mark.svg"
                  alt="via email address"
                />
              </div>
              <span class="font-bold text-xs ml-3.5">email address</span>
            </div>
          </div>
          <custom-button @sendAction="sendCode">
            <span slot="button-text">Send Code</span>
          </custom-button>
        </div>
      </section>
    </template>
  </onboarding-scaffold>
</template>

<script>
import OnboardingScaffold from '@/components/OnboardingScaffold.vue'
import FormScaffold from '@/components/FormScaffold.vue'
import CodeInput from 'vue-verification-code-input'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'VerifyAccount',
  components: {
    OnboardingScaffold,
    FormScaffold,
    CodeInput,
  },
  watch: {
    tokenValidityPeriod() {
      if (this.tokenValidityPeriod === 0) {
        clearInterval(this.intervalId)
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  mounted() {
    this.timeTracker()

    if (this.prevRoute && this.prevRoute.name === 'Login') {
      this.notification = {
        type: 'error',
        message: this.$route.query.message,
      }
    }
  },
  data() {
    return {
      verificationToken: null,
      chooseVerifyMode: false,
      verification_mode: '',
      isEmail: false,
      isSMS: false,
      tokenValidityPeriod: 1000,
      intervalId: null,
      notification: null,
      prevRoute: null,
    }
  },
  computed: {
    ...mapGetters(['username']),
  },
  methods: {
    timeTracker() {
      this.intervalId = setInterval(() => {
        if (this.tokenValidityPeriod > 0) {
          this.tokenValidityPeriod -= 1
        }
      }, 1000)
    },
    onComplete(value) {
      this.verificationToken = value
    },
    chooseEmail() {
      this.isEmail = true
      this.isSMS = false
      this.verification_mode = 'email'
    },
    chooseSMS() {
      this.isEmail = false
      this.isSMS = true
      this.verification_mode = 'sms'
    },
    async sendCode() {
      try {
        const { status, data } = await this.axios.patch('/register', {
          username: this.username,
          verification_mode: this.verification_mode,
        })

        if ((status >= 200) & (status < 300)) {
          this.chooseVerifyMode = false
          this.notification = {
            type: 'success',
            message: data.message,
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async send() {
      try {
        if (this.tokenValidityPeriod === 0) {
          this.notification = {
            type: 'error',
            message:
              'Verification code expired, kindly request for new verification code.',
          }

          return
        }

        const { status } = await this.axios.post('/register/verify', {
          username: this.username,
          verification_code: this.verificationToken,
        })

        if (status >= 200 && status < 300) {
          this.$router.push({
            name: 'CreatePassword',
            query: { auth: this.$route.query.auth },
          })
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
